import { FooterColumnType } from '@/types/Contentful/Footer'

export const FOOTER_SECTIONS: readonly FooterColumnType[] = [
  {
    title: 'Our Company',
    links: [{
      displayText: 'About Us',
      url: '/about-us',
      newTab: false,
      clickable: true,
      ariaLabel: 'Learn more about One Kings Lane',
    },
    {
      displayText: 'Careers',
      url: '/careers',
      newTab: false,
      clickable: true,
      ariaLabel: 'Join the One Kings Lane team',
    },
    {
      displayText: 'Blog',
      url: 'https://blog.onekingslane.com/',
      newTab: false,
      clickable: true,
      ariaLabel: 'Read the One Kings Lane blog',
    }],
  },
  {
    title: 'Customer Care',
    links: [
      {
        displayText: 'Returns',
        url: 'https://returns.narvar.com/okl/returns',
        newTab: false,
        clickable: true,
        ariaLabel: 'Return an item',
      },
      {
        displayText: 'Order Status',
        url: '/order-status',
        newTab: false,
        clickable: true,
        ariaLabel: 'Check the status of your order',
      }, {
        displayText: 'FAQ',
        url: '/faq',
        ariaLabel: 'Frequently asked questions',
        newTab: false,
        clickable: true,
      },
      {
        displayText: 'Return Policy',
        url: '/faq/#returns-exchange',
        newTab: false,
        clickable: true,
        ariaLabel: 'Return policy',
      },
      {
        displayText: 'Shipping and Delivery',
        url: '/faq/#shipping-delivery',
        ariaLabel: 'Shipping/Delivery Frequently asked questions',
        newTab: false,
        clickable: true,
      },
      {
        displayText: 'Request a Swatch',
        url: '/swatch-request',
        newTab: false,
        clickable: true,
        ariaLabel: 'Request a swatch',
      },
      {
        displayText: 'Contact Us',
        url: '/contact-us',
        newTab: false,
        clickable: true,
        ariaLabel: 'Contact One Kings Lane',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        displayText: 'Free Design Services',
        url: '/design-services',
        newTab: false,
        clickable: true,
        ariaLabel: 'Learn more about our free design services',
      },
      {
        displayText: 'To The Trade',
        url: '/to-the-trade',
        newTab: false,
        clickable: true,
        ariaLabel: 'Learn more about our trade program',
      },
      {
        displayText: 'Affiliates',
        url: '/affiliates-apply-now',
        newTab: false,
        clickable: true,
        ariaLabel: 'Join our affiliate program',
      },
      {
        displayText: 'Farrow & Ball',
        url: '/farrow-ball',
        newTab: false,
        clickable: true,
        ariaLabel: 'Shop Farrow & Ball',
      },
      {
        displayText: 'Protection Plan',
        url: '/extend-faq',
        newTab: false,
        clickable: true,
        ariaLabel: 'Learn more about our protection plan',
      },
      // {
      //   displayText: 'Citizens Pay®',
      //   url: '/citizens-pay-faq',
      // },
      {
        displayText: 'Extend',
        url: '/extend-faq',
        newTab: false,
        clickable: true,
        ariaLabel: 'Learn more about Extend',
      },
      {
        displayText: 'Product Collections',
        url: '/productcollections',
        newTab: false,
        clickable: true,
        ariaLabel: 'Shop our product collections',
      },
      {
        displayText: 'Site Map',
        url: '/htmlsitemap',
        newTab: false,
        clickable: true,
        ariaLabel: 'View the One Kings Lane site map',
      },
    ],
  },
]
