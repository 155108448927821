import React from 'react'
import { FOOTER_SECTIONS } from '@/data/constants/FOOTER_SECTIONS'
import dynamic from 'next/dynamic'

import Row from '@csc/dls/Row'
import Col from '@csc/dls/Col'
import Link from '@csc/dls/Link'
import Text from '@csc/dls/Text'
import Heading4 from '@csc/dls/Heading4'
import { FooterLinkType, FooterColumnType } from '@/types/Contentful/Footer'

const Plus = dynamic(import('@csc/dls/Icons/Plus'))
const Minus = dynamic(import('@csc/dls/Icons/Minus'))

const Panel = dynamic(import('@csc/dls/Panel'))
const Accordion = dynamic(import('@csc/dls/Accordion'))

const FooterLinks: React.FC<{ links: readonly FooterLinkType[] }> = ({ links }) => (
  <>
    {links.map((link) => (
      <Link
        key={`${link.displayText}_${link.url}`}
        aria-label={link?.ariaLabel || link.displayText}
        className="no-underline mb-3.5 px-4 lg:px-0"
        href={link.url}
        target={link.newTab ? '_blank' : '_self'}
        onClick={(e) => e.stopPropagation()}
      >
        <Text>{link.displayText}</Text>
      </Link>
    ))}
  </>
)

const FooterAccordions:React.FC<{
  footerSections?: readonly FooterColumnType[] | null
}> = ({
  footerSections = FOOTER_SECTIONS,
}) => (
  <>
    <div className="block lg:hidden border-t">
      <Accordion
        radio={false}
        singleOpen
        id="FooterAccordions"
        IconComponent={Plus}
        expandedIconId={Minus}
      >
        {(footerSections || FOOTER_SECTIONS).map((section) => (
          <Panel
            header={(
              <Heading4 textTag="p" id={`${section.title}-labelId`}>
                {section.title}
              </Heading4>
              )}
            key={JSON.stringify(section.links)}
            className="py-2 px-4"
            id={section.title}
            sectionId={`footer-section-${section.title}`}
          >
            <FooterLinks links={section.links} />
          </Panel>
        ))}
      </Accordion>
    </div>
    <div className="hidden lg:block">
      <Row cols={6}>
        {(footerSections || FOOTER_SECTIONS).map((section) => (
          <Col
            span={{ sm: 6, md: 2 }}
            key={JSON.stringify(section.links)}
          >
            <Heading4 textTag="p" className="mb-3.5">{section.title}</Heading4>
            <FooterLinks links={section.links} />
          </Col>
        ))}
      </Row>
    </div>
  </>
)

export default FooterAccordions
